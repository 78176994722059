@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --main-100: #262262;
    --main-50: #7d7aa1;
    --main-10: #e9e9ef;
    --main-orange-100: #f77b42;
    --main-orange-50: #fab08e;
    --main-orange-10: #fef2ec;
}

/*html {*/
/*    background: url(../img/bg.jpg) repeat;*/
/*    -webkit-background-size: cover;*/
/*    -moz-background-size: cover;*/
/*    -o-background-size: cover;*/
/*    background-size: cover;*/
/*}*/

body {
    font-family: 'Inter', sans-serif;
    font-size:16px;
    line-height:1.4em;
    overflow-y: auto;
    overflow-x: hidden;
    background:transparent;
    color:#262262;
    color:var(--main-100);
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 900;
}
.container {
    max-width: none;
}

.navbar {
    background-color:#ffff;
}

.active {
    font-weight: 900 !important;
    color: #f77b42 !important;
    color: var(--main-orange-100) !important;
}

.hero {
    background:rgba(212, 211, 224,0.2);
    /*padding: 150px 50px;*/
    min-height: 400px;
}

.clients {
    background:rgba(212, 211, 224,0.2);
    padding: 50px 50px;
    overflow-x: hidden;
    overflow-y: hidden;
    /*white-space: nowrap;*/
    margin-bottom:40px;
}

.client-item {
    padding: 0 20px 0 0;
    font-weight:900;
    font-size: 1.5em;
    opacity: 0.5;
    line-height:2em;
    white-space: nowrap;
}

.background{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    opacity:0.4;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 10px;
}

.card-title {
    font-weight: 900;
}

.btn {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.btn-outline-primary {
    color: #262262;
    color: var(--main-100);
    border-color: #262262;
    border-color: var(--main-100);
}

.btn-outline-primary:hover,.btn-outline-primary:active,.btn-outline-primary:focus {
    color: #262262;
    color: var(--main-100);
    border-color: #262262;
    border-color: var(--main-100);
    background:#e9e9ef;
    background:var(--main-10);
}

.badge {
    background: #e9e9ef !important;
    background: var(--main-10) !important;
    color: #262262 !important;
    color: var(--main-100) !important;
}

.projects .badge {
    margin-right:5px;
}

.text-warning {
    font-size:0.6em;
    color:#fab08e !important;
    color:var(--main-orange-50) !important;
}

.btn.disabled, .btn:disabled, fieldset:disabled {
    opacity: 0.3;
}
.btn-outline-primary {
    background: #ffff;
}

